import { useContext } from "react";
import { Context } from "../context";
import { pageStyle, smallFont, mediumFont, largeFont } from "../styles";

const Flatpage = ({ children }) => {
  const { theme } = useContext(Context);
  return (
    <div className="section">
      <style jsx>{pageStyle}</style>
      <style jsx global>{`
        a {
          color: ${theme.primaryTextColor};
        }
        h2,
        h3,
        h4,
        p,
        ul,
        address,
        ol,
        li {
          line-height: 150%;
        }
        h2,
        address,
        h3,
        h4,
        p,
        ul,
        ol,
        li {
          font-size: ${smallFont};
          margin: 1rem 0;
        }
        h2 {
          font-size: ${largeFont};
          margin: 2rem 0;
        }
        h3 {
          font-size: ${mediumFont};
          margin: 2rem 0;
        }
      `}</style>
      <style jsx>
        {`
          .flatpage {
            padding: 2rem 0;
          }
        `}
      </style>
      <div className="flatpage">{children}</div>
    </div>
  );
};
export default Flatpage;
